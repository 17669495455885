import React from 'react'
import styled, { css } from 'styled-components'

export const Button = styled.button`
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ${(props) => props.theme.typography.paragraph.S}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;

  color: ${(props) => props.theme.button.normal.text};
  background: ${(props) => props.theme.button.normal.background};
  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.button.hover.background};
  }
`

export const SmallButton = styled(Button)`
  width: 137px;
  height: 37px;
`

export const LongButton = styled(Button)`
  width: 100%;
  max-width: 500px;
  height: 74px;
`
