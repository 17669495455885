import styled, { css } from 'styled-components'
import { device } from '@src/style'

export const On = styled.div`
  display: none;
  width: 100%;

  ${props =>
    props.mobile &&
    css`
      @media ${device.only.mobile} {
        display: flex;
      }
    `}

  ${props =>
    props.tablet &&
    css`
      @media ${device.only.tablet} {
        display: flex;
      }
    `}

  ${props =>
    (props.laptop || props.desktop) &&
    css`
      @media ${device.only.laptops} {
        display: flex;
      }
      @media ${device.only.desktop} {
        display: flex;
      }
    `}
`