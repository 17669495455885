import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from 'react'
import styled from 'styled-components'
import { useWidthDetector } from './useWidthDetector'

const _Wrapper = styled.div``

const _Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  opacity: 0.8;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`

const _Masonry = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ spacing }) => `${spacing}px`};
`

export default ({
  writeLogs = false,
  imagesData,
  spacing,
  wantedRowHeight,
  onCreateRow,
}) => {
  const log = (...message) => {
    if (writeLogs) console.log('PrecachedMosaic', ...message)
  }

  const ref = useRef(null)

  const [loadingProgress, setLoadingProgress] = useState(null)
  const [parentWidth, setParentWidth] = useState(null)
  const [preparedImages, setPreparedImages] = useState([])
  const [masonryRows, setMasonryRows] = useState([])

  const _prefetchImagesData = imagesData => {
    var cachedImages = []
    const pushData = (resolve, item) => {
      cachedImages = [...cachedImages, { ...item }]
      if (cachedImages.length == imagesData.length) {
        resolve(cachedImages)
      }
    }
    return new Promise((resolve, reject) => {
      imagesData.forEach(item => {
        if (!item.url && writeLogs) {
          console.error('invalid item', item)
          return
        }
        if (item.width > 0 && item.height > 0) {
          pushData(resolve, {
            ...item,
          })
          return
        }

        const img = new Image()
        img.onload = e => {
          setLoadingProgress(
            `Prefetching ${cachedImages.length}/${imagesData.length}`
          )
          pushData(resolve, {
            ...item,
            width: e.target.width,
            height: e.target.height,
          })
        }
        img.src = item.url
      })
    })
  }

  const __prepareMasonry = (
    imagesData,
    { wantedRowWidth, wantedRowHeight, spacing = 0, maxHeight = 500 }
  ) => {
    setLoadingProgress(`Processing`)
    if (
      !imagesData ||
      imagesData.length == 0 ||
      !wantedRowWidth ||
      !wantedRowHeight
    ) {
      if (writeLogs) {
        console.error(
          'Invalid params ',
          imagesData,
          wantedRowWidth,
          wantedRowHeight
        )
      }
      return
    }

    const _sizeOneImage = (imageData, wantedHeight, spacing, firstChild) => {
      const { width, height } = imageData

      const spacedWidth = firstChild ? width : width - spacing
      const spacedHeight = height - spacing

      const ratio = width / height
      const wantedWidth = wantedHeight * ratio
      return {
        ...imageData,
        spacing: spacing,
        width: wantedWidth,
        height: wantedHeight,
      }
    }

    const _totalWidth = imagesData =>
      imagesData.reduce((sum, item) => sum + item.width, 0)

    log('_prepareMasonry start', imagesData, wantedRowWidth, wantedRowHeight)

    var rowIndex = 0
    var newRow = true
    const rows = imagesData
      .reduce((acc, item) => {
        //Size every image to planned height and split all images into rows
        const row = (acc && acc[rowIndex]) || []
        const sizedImage = _sizeOneImage(item, wantedRowHeight, spacing, newRow)
        newRow = false
        row.push(sizedImage)
        const rowWidth = _totalWidth(row)
        acc[rowIndex] = row
        if (rowWidth >= wantedRowWidth) {
          rowIndex++
          newRow = true
        }
        return acc
      }, [])
      .map(row => {
        //adjust image height until row would fit the screen
        const rowWidth = _totalWidth(row)
        const delta = wantedRowWidth / rowWidth

        return row.map((item, index) => {
          const newHeight = Math.min(maxHeight, item.height * delta)
          return _sizeOneImage(item, newHeight, spacing, index == 0)
        })
      })
    setMasonryRows(rows)
  }

  const _prepareMasonry = useCallback(
    (...props) => {
      __prepareMasonry(...props)
    },
    [parentWidth, preparedImages]
  )

  useEffect(() => {
    log('onNewWidth', parentWidth, preparedImages)

    _prepareMasonry(preparedImages, {
      wantedRowHeight: wantedRowHeight,
      wantedRowWidth: parentWidth,
      maxHeight: 500,
      spacing: spacing.inner,
    })
    setLoadingProgress(null)
  }, [parentWidth, preparedImages])

  useEffect(() => {
    ;(async () => {
      const cachedImages = await _prefetchImagesData(imagesData)
      log('Prefetched', cachedImages)
      setPreparedImages(cachedImages)
    })()
    log('didMount')
  }, [imagesData])

  useWidthDetector(ref, () => {
    const containerWidth = ref.current ? ref.current.clientWidth : 0
    setParentWidth(containerWidth)
    log('useWidthDetector', containerWidth)
  })

  return (
    <_Wrapper>
      {loadingProgress && (
        <_Loading>{loadingProgress} images, please wait</_Loading>
      )}
      <_Masonry spacing={spacing.outer} ref={ref}>
        {masonryRows.map((row, rowIndex) => {
          return onCreateRow(row, rowIndex)
        })}
      </_Masonry>
    </_Wrapper>
  )
}
