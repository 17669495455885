import React from 'react'
import SEO from '@src/components/seo'
import Img from 'gatsby-image'
import logo from '@src/images/logo.png'
import styled, { css } from 'styled-components'
import typography from '@src/style/typography'
import { Button } from '@src/style/buttons'
import { GlobalWidthLimiter, H1, H2, H3, PM } from '@src/style/style'
import { On } from '@src/style/on'
import device from '@src/style/device'
import Mosaic from '@components/shared/react-masonry-lightbox/cachingMosaic'
import { FadingImage } from '@components/mosaicCard'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { useMailerLite } from '@components/useMailerLite'

import image1 from '@src/images/01.jpeg'
import image2 from '@src/images/02.jpeg'
import image3 from '@src/images/03.jpeg'
import image4 from '@src/images/04.jpeg'
import image5 from '@src/images/05.jpeg'
import image6 from '@src/images/06.jpeg'
import image7 from '@src/images/07.jpeg'

const entries = [
  {
    height: 400,
    width: 530,
    url: image1,
  },
  {
    height: 400,
    width: 600,
    url: image2,
  },
  {
    height: 400,
    width: 286,
    url: image3,
  },
  {
    height: 400,
    width: 600,
    url: image7,
  },
  {
    height: 400,
    width: 320,
    url: image4,
  },
  {
    height: 400,
    width: 600,
    url: image6,
  },
  {
    height: 400,
    width: 1567,
    url: image5,
  },
]

const Logo = styled.img`
  width: 40px;
  height: 40px;
  padding: 1rem;
`

const LogoBig = styled.div`
  background: ${({ theme }) => theme.colors.menuBackground};
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
    height: 70%;
  }
`

const RowWrapper = styled.div`
  background: ${({ theme, black, transparent }) => {
    if (!transparent) {
      return black ? theme.colors.menuBackground : theme.colors.background
    } else return `none`
  }};
  ${({ landing }) =>
    landing &&
    css`
      padding: 3rem 0;
    `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ left }) =>
    left &&
    css`
      justify-content: start;
    `}
  p {
    ${typography.paragraph.L}
    color: ${({ theme }) => theme.colors.menuText};
  }
`

const WrappingRow = styled(Row)`
  flex-wrap: wrap;
  @media ${device.tablet} {
    justify-content: center;
  }
  & > div {
    margin: 0 1rem 0 0;
    width: 100%;

    @media ${device.tablet} {
      width: 48%;
    }
    @media ${device.laptop} {
      margin-right: 2rem;
      width: 30%;
    }
  }
`

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ left }) =>
    left &&
    css`
      align-items: flex-start;
    `}
  ${({ right }) =>
    right &&
    css`
      align-items: flex-end;
    `}
`
const IntroCol = styled(Col)`
  width: 100%;
  max-width: 600px;
  align-items: flex-start;
`
const WhyCol = styled(Col)`
  ${({ theme }) => theme.blogWidthLimiter};
`

const FinalCol = styled(Col)`
  height: 400px;
  display: flex;
  justify-content: space-between;
`

const _H1 = styled(H1)`
  color: white;
`
const _H2 = styled(H2)`
  color: white;
  padding-bottom: 6rem;
`
const _H3 = styled(H3)`
  color: white;
`
const _PM = styled(PM)`
  padding: 1rem 0 2rem 0;
`

const _Button = styled(Button)`
  margin-top: 1rem;
`

const _LogoRow = styled(Row)`
  justify-content: start;
  align-items: center;
  p {
    ${typography.paragraph.L}
    color: ${({ theme }) => theme.colors.menuText};
  }
`

const _Gallery = styled.div`
  position: relative;
  overflow: hidden;
  height: 600px;
`

const _GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(12, 12, 12);
  background: linear-gradient(
    180deg,
    rgba(12, 12, 12, 1) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(12, 12, 12, 1) 80%
  );
`

const _GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
`

const MainButton = () => {
  const [mlAccount, setMlAccount] = React.useState(null)
  useMailerLite((account) => {
    setMlAccount(() => account)
  })
  return (
    <>
      {mlAccount && (
        <_Button
          onClick={() => {
            mlAccount('webforms', '2075954', 'l2l2y9', 'show')
          }}
        >
          Get Launch Updates
        </_Button>
      )}
    </>
  )
}

const LogoRow = () => {
  return (
    <RowWrapper black>
      <_LogoRow>
        <Logo src={logo} alt="Kuchi Photo Logo" />
        <p>Kuchi</p>
      </_LogoRow>
    </RowWrapper>
  )
}

const _LandingRow = ({ black, transparent, children }) => (
  <RowWrapper landing black={black} transparent={transparent}>
    <GlobalWidthLimiter>
      <Row center>{children}</Row>
    </GlobalWidthLimiter>
  </RowWrapper>
)
const Row1 = () => {
  return (
    <_LandingRow>
      <IntroCol>
        <_H1>Home for your photography</_H1>
        <_PM>
          Get a free online portfolio with modern gallery. Share and get
          meaningful feedback. Grow your following and get paid.
        </_PM>
        <MainButton />
      </IntroCol>
      <On tablet desktop>
        <Col right>
          <LogoBig>
            <Logo src={logo} alt="Kuchi Photo Logo" />
          </LogoBig>
        </Col>
      </On>
    </_LandingRow>
  )
}

const Row2 = () => {
  return (
    <_LandingRow black>
      <Col>
        <_H2>What do you get</_H2>
        <WrappingRow>
          <Col left>
            <_H3>Free online portfolio</_H3>
            <_PM>
              You will have a personal page with a free online portfolio built
              on a modern mosaic gallery. Showcase your best images while
              keeping the ownership over images.
            </_PM>
          </Col>
          <Col left>
            <_H3>Meaningful feedback</_H3>
            <_PM>
              Participate in global and local leaderboards by sharing your
              images with others and getting meaningful feedback. See all of the
              engagement numbers.
            </_PM>
          </Col>
          <Col left>
            <_H3>Get paid back</_H3>
            <_PM>
              Build your personal following, showcase your tutorials, courses,
              tours. Communicate with your fans in an easy way and get paid by
              your peers.
            </_PM>
          </Col>
        </WrappingRow>
      </Col>
    </_LandingRow>
  )
}

const Row3 = () => {
  return (
    <>
      <_LandingRow>
        <WhyCol>
          <_H2>Why do we do it</_H2>
          <_PM>
            We loved 500px, it started as a great service for photography
            community, but, sadly, aiming for bigger money, now is no more than
            a stock. Flickr was great, but it's long due a refresh. Instagram is
            where we sell, but not where we get appreciated. Facebook doesn't
            want to show our images to our subscribers. Small local photo
            communities are seldom maintained and are mostly alive on
            enthusiasms of a few supporters.
          </_PM>
          <_PM>
            We believe it is time to build a new home for photographers. A place
            to get inspired, stay in touch, find new friends, see images in
            amazing gallery. And a place where those who appreciate our work can
            pay us back and learn something new in return. A place, where we
            retain the ownership over what we created while still getting
            meaningful feedback.
          </_PM>
        </WhyCol>
      </_LandingRow>
      <_Gallery>
        <Mosaic
          spacing={{
            inner: 5,
            outer: 5,
          }}
          imagesData={entries}
          wantedRowHeight={400}
          onCreateRow={(row, rowIndex) => {
            return (
              <_GalleryRow>
                {row.map((item, index) => {
                  const key = (rowIndex + 1) * (index + 1) * 123
                  const photoIndex = entries.indexOf(
                    entries.filter((imageData) => item.url == imageData.url)[0]
                  )
                  return (
                    <FadingImage
                      key={key}
                      item={item}
                      aKey={`FadingImage${key}`}
                      onClick={() => {}}
                    />
                  )
                })}
              </_GalleryRow>
            )
          }}
        />
        <_GalleryOverlay>
          <_LandingRow transparent>
            <FinalCol>
              <_H2>We launch as soon as we're ready!</_H2>
              <MainButton />
            </FinalCol>
          </_LandingRow>
        </_GalleryOverlay>
      </_Gallery>
    </>
  )
}

const IndexPage = () => {
  return (
    <>
      <SEO />
      <LogoRow />
      <Row1 />
      <Row2 />
      <Row3 />
      <LogoRow />
    </>
  )
}

export default IndexPage
