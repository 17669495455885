import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import { device } from '@src/style'

const _ImageLoaderFrames = keyframes`
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`
const _ImageLoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  height: 100%;
  z-index: ${({ loaded }) => (loaded ? -1 : 1)};;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ loaded }) => (loaded ? 0 : 1)};
`
const _ImageLoader = styled.div`
  position: relative;
  width: ${({ loaderWidth }) => loaderWidth}px;
  height: ${({ loaderHeight }) => loaderHeight}px;

  div {
    position: absolute;
    width: ${({ loaderWidth }) => loaderWidth / 5}px;
    height: ${({ loaderHeight }) => loaderHeight / 5}px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    animation: ${_ImageLoaderFrames} 1.2s linear infinite;
  }
  div:nth-child(1) {
    top: ${({ loaderWidth }) => loaderWidth / 10}px;
    left: ${({ loaderHeight }) => loaderHeight / 10}px;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: ${({ loaderWidth }) => loaderWidth / 10}px;
    left: ${({ loaderHeight }) => loaderHeight / 2.5}px;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: ${({ loaderWidth }) => loaderWidth / 10}px;
    left: ${({ loaderHeight }) => loaderHeight / 1.428571429}px;
    animation-delay: -0.8s;
  }
  div:nth-child(4) {
    top: ${({ loaderWidth }) => loaderWidth / 2.5}px;
    left: ${({ loaderHeight }) => loaderHeight / 10}px;
    animation-delay: -0.4s;
  }
  div:nth-child(5) {
    top: ${({ loaderWidth }) => loaderWidth / 2.5}px;
    left: ${({ loaderHeight }) => loaderHeight / 2.5}px;
    animation-delay: -0.8s;
  }
  div:nth-child(6) {
    top: ${({ loaderWidth }) => loaderWidth / 2.5}px;
    left: ${({ loaderHeight }) => loaderHeight / 1.428571429}px;
    animation-delay: -1.2s;
  }
  div:nth-child(7) {
    top: ${({ loaderWidth }) => loaderWidth / 1.428571429}px;
    left: ${({ loaderHeight }) => loaderHeight / 10}px;
    animation-delay: -0.8s;
  }
  div:nth-child(8) {
    top: ${({ loaderWidth }) => loaderWidth / 1.428571429}px;
    left: ${({ loaderHeight }) => loaderHeight / 2.5}px;
    animation-delay: -1.2s;
  }
  div:nth-child(9) {
    top: ${({ loaderWidth }) => loaderWidth / 1.428571429}px;
    left: ${({ loaderHeight }) => loaderHeight / 1.428571429}px;
    animation-delay: -1.6s;
  }
`
export const ImageLoader = ({ loaded }) => {
  return (
    <_ImageLoaderWrapper loaded={loaded}>
      <_ImageLoader loaderWidth={30} loaderHeight={30}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </_ImageLoader>
    </_ImageLoaderWrapper>
  )
}
